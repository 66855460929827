//  Showcase
#showcase {
  margin-top: -80px;
  position: relative;
  background: url('../img-background/showcase.jpg') no-repeat center
    center/cover;
  min-height: 600px;

  .has-overlay::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(black, 0.25);
  }
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
  .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    max-width: 1000px;
    height: 100vh;
    min-height: 700px;
    margin: 80px auto 0 auto;
    padding: 0 2%;
  }
  .title {
    color: $primary-color;
    font-family: $font-script;
    font-size: 2.2rem;
    font-weight: 600;
    text-align: center;
    padding-bottom: 0rem;
    text-transform: uppercase;
    span {
      margin-bottom: 1.5rem;
      display: block;
      font-weight: unset;
      &.normal {
        margin-bottom: unset;
        display: unset;
        font-family: font-family !important;
      }
    }

    @media (max-width: 991.98px) {
      font-size: 1.8rem;
    }
    @media (max-width: 575.98px) {
      font-size: 1.5rem;
    }
  }
}
