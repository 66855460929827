.testimonials {
  padding: 1rem 0rem 3rem 0rem;
  text-align: center !important;
  background-color: $secondary-color;
  h3 {
    text-align: center;
    font-size: 2.4rem;
    color: $primary-color;
    text-transform: uppercase;
    font-weight: 400;
  }
  .desc {
    text-align: center;
    font-size: 1.5rem;
  }
  hr {
    width: 60px;
    color: $primary-color;
    height: 4px;
    background-color: $primary-color;
    margin: auto;
    border: none;
    margin-top: 0rem;
    margin-bottom: 0.4rem;
  }
  .card {
    border-bottom: 3px $primary-color solid !important;
    transition: $transition;
    min-height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0rem 4px;
    margin-top: 60px;
    background-color: #fff;
    color: #333;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.2),
      0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.1),
      0 16px 16px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    transition: all 0.4s ease-out;
    cursor: pointer;
    @media (max-width: 1199.98px) {
      min-height: 210px;
    }
    @media (max-width: 991.98px) {
      min-height: 230px;
    }
    &:hover {
      transform: translateY(-12px) !important;
    }
  }
  .card-img-top {
    background-color: $primary-color;
    color: #ffffff;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    line-height: 75px;
    margin: -40px auto 0 auto;
  }
  .card-body {
    padding-top: 1rem;
  }
  .card-footer {
    padding: 0.5rem 0rem 1rem 0rem;
    color: lighten(#333, 30%);
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      padding-right: 0.4rem;
      color: $primary-color;
    }
  }
  .quote {
    font-size: 0.4rem;
    font-weight: 600;
    color: $primary-color;
    transform: translateY(-10px);
    margin-right: 0.2rem;
  }
  @media (max-width: 991.98px) {
    .testimonial_2 {
      display: none;
    }
  }
  @media (max-width: 767.98px) {
    .testimonial_1 {
      display: none;
    }
  }
  @media (max-width: 575.98px) {
    .testimonial_1 {
      display: none;
    }
    h3 {
      font-size: 2.5rem;
    }
    .desc {
      font-size: 1.2rem;
    }
  }
}
