/* von Netflix Traversy-Media  und sass youtube kurse*/
// $primary-color: #42484e;
$primary-color: #351652; // #71256d; // #81dbd2;
$secondary-color: #f8f9fa;
$third-color: lighten($primary-color, 65%);
$zero-color: lighten($primary-color, 45%);

$button-color: #c13929;
$border-color: lighten(#333, 60%);

$dark-color: #0b1826;
$blue-color: darken(#1a73e8, 5%);
$gray-color: #999;
$light-color: #f4f4f4;
$red-color: #dc3545;
$dark-red-color: #c5211e;
$green-color: #34a853;
$orange-color: #fabc05;

$max-width: 968px;

$primary-overlay: lighten($primary-color, 20%);

@font-face {
  font-family: 'Handlee';
  src: url('/src/fonts/Handlee/Handlee-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Catamaran';
  src: url('/src/fonts//Catamaran/static/Catamaran-Regular.ttf') format('truetype');
}

$transition: all 0.4 ease-out;
$font-script: 'Handlee', cursive;
$font-family: 'Catamaran', Helvetica, sans-serif;
