.social_media {
  padding-bottom: 3rem;
  .title-script {
    color: $primary-color;
    font-family: $font-script;
    font-size: 2.5rem;
    text-align: center;
    padding-bottom: 2rem;
  }
  .z-container {
    height: 80px;
    width: 100%;
    max-width: 400px;
    // box-shadow: -7px -7px 17px rgba(255, 255, 255, 0.6),
    //   7px 7px 17px rgba(70, 70, 70, 0.15);
    // border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: auto;
    a {
      height: 60px;
      width: 60px;
      box-shadow: -7px -7px 17px rgba(255, 255, 255, 0.6),
        7px 7px 17px rgba(70, 70, 70, 0.15);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-size: 30px;
      transition: all 0.25 ease-out;
      &:hover {
        box-shadow: inset -7px -7px 17px rgba(255, 255, 255, 0.6),
          inset 7px 7px 17px rgba(70, 70, 70, 0.15);
      }
    }
  }

  .instagram-img {
    height: 32px;
    width: auto;
  }

  .facebook-img {
    height: 40px;
    width: auto;
  }
  .fa-twitter {
    color: #23abff;
  }
  .fa-facebook-square {
    color: #3e68c0;
  }
  .fa-instagram {
    color: #ea3145;
  }
}

@media (max-width: 575.98px) {
  .social_media {
    .title-script {
      font-size: 2.4rem;
    }
  }
}
