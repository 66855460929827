.background-gradient {
  background: $zero-color;
  background: -moz-radial-gradient(
    top left,
    ellipse cover,
    $zero-color 0%,
    $third-color 57%
  );
  background: -webkit-gradient(
    radial,
    top left,
    0px,
    top left,
    100%,
    color-stop(0%, $zero-color),
    color-stop(57%, $third-color)
  );
  background: -webkit-radial-gradient(
    top left,
    ellipse cover,
    $zero-color 0%,
    $third-color 57%
  );
  background: -o-radial-gradient(
    top left,
    ellipse cover,
    $zero-color 0%,
    $third-color 57%
  );
  background: -ms-radial-gradient(
    top left,
    ellipse cover,
    $zero-color 0%,
    $third-color 57%
  );
  background: radial-gradient(
    ellipse at top left,
    $zero-color 0%,
    $third-color 57%
  );
}
