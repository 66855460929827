.kontakt-home {
  background-color: $primary-color;
  padding: 3rem 0rem;
  img {
    width: 200px;
    height: auto;
  }
  .content {
    max-width: 800px;
    margin: auto;
    padding: 2rem 0rem;
    color: #fff;
  }
  .title {
    font-weight: 600;
    color: #fff;
    font-size: 2.2rem;
    text-align: center;
    padding-bottom: 1rem;
  }
  .desc {
    font-size: 1.3rem;
  }
  .kontakt-dates {
    max-width: 600px;
    margin: auto;
    padding-top: 2rem;
    text-align: center;
    a {
      color: #000000;
      font-size: 1.5rem;
      font-weight: 600;
      transition: all 0.8s ease-out;
      &:hover {
        color: #fff;
      }
    }
    i {
      color: #fff;
      margin-right: 0.5rem;
    }
  }
}
