.wavy-background {
  //   $minHeight: 600px;
  //   min-height: $minHeight;
  $height: 600px;
  height: $height;
  position: relative;

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    $height: 800px;
    height: $height;
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    $height: 900px;
    height: $height;
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 500.98px) {
    $height: 1000px;
    height: $height;
  }
  @media (max-width: 480.98px) {
    $height: 1300px;
    height: $height;
  }

  @media (max-width: 400.98px) {
    $height: 1500px;
    height: $height;
  }
  .context {
    width: 100%;
    position: absolute;
    // min-height: $minHeight;
    height: $height;
    padding-top: 2rem;
    padding-bottom: 8rem;
  }
  .content {
    max-width: 900px;
    margin: auto;
  }

  .context h1 {
    color: $primary-color;
    font-family: $font-script;
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    padding-bottom: 0rem;
  }
  .sub-title {
    text-align: center;
    font-size: 1.5rem;
  }

  .content-text {
    max-width: 750px;
    margin: auto;
    padding-top: 2rem;
    p {
      padding-bottom: 1rem;
      text-align: center;
      font-size: 1.2rem;
    }
  }
  .stichpunkte {
    text-align: center;
    h3 {
      display: inline-block;
      min-width: 180px;
      text-align: center;
      margin: auto;
      padding-bottom: 0.3rem;
      i {
        color: $primary-color;
        margin-right: 0.4rem;
        font-size: 0.7rem;
        transform: translateY(-2px);
      }
    }
  }
  .area {
    width: 100%;
    // height: $minHeight;
    height: $height;
  }

  .circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba($primary-color, 0.5);
    animation: animate 25s linear infinite;
    bottom: -150px;
  }

  .circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }

  .circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }

  .circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }

  .circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }

  .circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }

  .circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }

  .circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }

  .circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }

  .circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }

  .circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }

  @keyframes animate {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
    }

    100% {
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 100%;
    }
  }
}
