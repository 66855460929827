header {
  text-align: center;
  padding: 0.4rem 0rem;
  background-color: $primary-color;
  img {
    width: auto;
    height: 80px;
    object-fit: cover;
  }
}

.z-nav {
  width: 100%;
  position: relative;
  z-index: 1000;

  background-color: $primary-color;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
  .z-nav-pages {
    display: flex;
    padding: 0rem 2rem;
    align-items: center;
    .z-nav-page {
      padding-right: 2rem;
      a {
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 0.15em;

        display: inline-block;
        padding: 10px 20px;
        position: relative;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        &:after {
          background: none repeat scroll 0 0 transparent;
          bottom: 0;
          content: '';
          display: block;
          height: 2px;
          left: 50%;
          position: absolute;
          background: #fff;
          width: 0;
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
        }
        &:hover:after {
          width: 100%;
          left: 0;
        }
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .z-burger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.2rem;
    border-radius: 4px;
    cursor: pointer;
    div {
      width: 25px;
      height: 3px;
      background-color: #fff;
      margin: 2.5px;
      transition: $transition;
    }
  }
  @media (max-width: 991.98px) {
    .z-nav-pages {
      background: $third-color;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
        0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
        0 16px 16px rgba(0, 0, 0, 0.12);
      display: flex;
      position: absolute;
      right: 0px;
      min-width: 300px;
      max-width: 800px;
      top: 80px;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      transform: translateX(100%);
      z-index: 1000;
    }
    .z-nav-page {
      opacity: 0;
      padding: 1.2rem 0rem;
      padding-right: 0rem !important;
    }
    .z-burger {
      display: flex;
      z-index: 2000;
    }
  }
}
@media (max-width: 991.98px) {
  .mobile-overflow-wrapper {
    overflow-x: hidden;
  }
}

@keyframes navSlideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes navSlideOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.z-toggle {
  div {
    background-color: #fff !important;
  }
  .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  .line2 {
    opacity: 0;
  }
  .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}
