.bold-text {
  font-weight: 600;
}

.start-text {
  padding: 2rem 0rem;
  .content-img {
    display: none;
    height: 250px;
    width: 200px;
    margin: 1em auto;
    background-color: #fff;
    position: relative;
    &:after {
      position: absolute;
      content: "";
      top: -4px;
      right: -4px;
      bottom: -4px;
      left: -4px;
      background: linear-gradient(
        lighten($primary-color, 20%),
        lighten($primary-color, 15%),
        lighten($primary-color, 10%),
        lighten($primary-color, 5%),
        $primary-color
      );
      z-index: -1;
    }
    img {
      text-align: center;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 767.98px) {
      display: block;
    }
  }
  .title-script {
    color: $primary-color;
    font-family: $font-script;
    font-size: 3.2rem;
    font-weight: 600;
    text-align: center;
    padding-bottom: 1rem;
  }
  .script {
    font-family: $font-script;
    font-size: 1.6rem;
    font-weight: 600;
  }
  .never {
    text-align: center;
    .big {
      font-weight: 600;
      text-transform: uppercase;
    }
    @media (max-width: 420.98px) {
      .big {
        display: block;
        padding-top: 0.4rem;
      }
    }
  }
  .z-br {
    margin: 1.6rem;
  }
  p {
    font-size: 1.2rem;
  }
  .motto {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    padding: 1rem 0rem;
  }
  .ende-text {
    color: $primary-color;
    font-family: $font-script;
    font-size: 3rem;
    text-align: center;
  }
  .img-col {
    @media (max-width: 767.98px) {
      display: none;
    }
  }
  .img_container {
    position: relative;
    width: 250px;
    height: 370px;
    margin: 1em auto 0;
    background-color: #fff;
    &:after {
      position: absolute;
      content: "";
      top: -4px;
      right: -4px;
      bottom: -4px;
      left: -4px;
      background: linear-gradient(
        lighten($primary-color, 20%),
        lighten($primary-color, 15%),
        lighten($primary-color, 10%),
        lighten($primary-color, 5%),
        $primary-color
      );
      z-index: -1;
    }
    img {
      text-align: center;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.startseite-bild-text {
  .img-col {
    padding: 0;
    margin: 0;
    // max-height: 500px;
    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }
  .content-col {
    background-color: #ffffff;
  }
  // .content-col-1 {
  //   background-color: $third-color;
  // }
  .content {
    width: 100%;
    height: 100%;
    padding: 3rem 2%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      text-align: center;
      font-size: 2rem;
    }
    hr {
      background-color: #000000;
    }

    .desc {
      font-size: 1.1rem;
      max-width: 600px;
    }
    ul {
      max-width: 500px;
      margin: auto;
      li {
        display: flex;
        align-items: flex-start;
        padding-bottom: 0.75rem;
        i {
          margin-right: 0.5rem;
          font-size: 0.7rem;
          transform: translateY(7px);
        }
      }
    }
    @media (max-width: 767.98px) {
      h2 {
        font-size: 1.7rem;
      }
      .desc {
        font-size: 1.2rem;
      }
    }
    @media (max-width: 567.98px) {
      h2 {
        font-size: 1.5rem;
      }
      .desc {
        font-size: 1rem;
      }
    }
  }
}
