// Utilies
// fixed scrolling Traversy Media
.img-attachment {
  position: relative;
  background: url('../img-background/yoga-quote-2.jpeg') no-repeat center
    center/cover;
  min-height: 500px;
  //   opacity: 0.6;
  background-attachment: fixed;
  text-transform: uppercase;
  .has-overlay::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background-color: rgba(#ffffff, 0.2);
  }
  p {
    padding: 0.6rem;
    z-index: 100;
    font-family: $font-script;
    // background-color: #fff;
    position: absolute;
    top: 50%;
    width: 100%;
    max-width: 900px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #000000;
    font-size: 1.6rem;
    font-weight: 600;
  }
  @media (max-width: 767.98px) {
    p {
      max-width: 600px;
      font-size: 1.2rem;
      padding: 0.4rem;
    }
  }
  @media (max-width: 575.98px) {
    p {
      max-width: 500px;
      padding: 0.2rem;
      letter-spacing: 0rem;
    }
  }
}
