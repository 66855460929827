// Footer
footer {
  background-color: rgba($primary-color, 0.5);
  a {
    color: #fff;
    transition: all 0.25s ease-out;
    &:hover {
      color: $primary-color;
      text-decoration: none;
    }
  }
  p {
    color: #000000;
  }

  ul {
    li {
      padding-bottom: 0.2rem;
      color: #fff;
    }
  }
  .copyright {
    color: $primary-color;
    font-size: 1.2rem;
    font-weight: 600;
  }
}
